import React from "react";
import PageHero from "../components/PageHero";
import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo";
import ContactForm from "../sections/contact/ContactForm";
import ContactInfo from "../sections/contact/ContactInfo";

const Contact = (props) => {
  return (
    <>
      <PageWrapper>
        <Seo 
          title="Sharptree | Contact | Mobile Reimagined"
          description="Sharptree provides a simpler approach to mobility for Maximo. We would love to hear from you and answer any questions you may have."
          pathname={props.location.pathname} 
        />
        <PageHero title="Contact Us" />
        <ContactInfo />
        <ContactForm />
      </PageWrapper>
    </>
  );
};
export default Contact;
