import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import styled from "styled-components";
import { Box, Button, Email, Input, Section, Text, Title } from "../../components/Core";

const FormStyled = styled.form``;

const functionURL = "https://sharptree-forms-8631.twil.io/sharptree-website"

const ContactForm = () => {

  const [isPosting, setIsPosting] = useState(false);
  const [postingError, setPostingError] = useState(false);
  const [postingSuccess, setPostingSuccess] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const postMail = async () => {

    setIsPosting(true);

    try {

      const res = await fetch(functionURL, {
        method: "post",
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: new URLSearchParams({ name, email, subject, message }).toString(),
      })

      if (!res.ok) {
        setPostingError(true);
      } else {
        setPostingSuccess(true);
      }
    } catch (e) {
      setPostingError(true);
    } finally {
      setIsPosting(false);
    }
  };

  const handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    if (name === "name") {
      setName(value)
    } else if (name === "email") {
      setEmail(value)
    } else if (name === "subject") {
      setSubject(value)
    } else if (name === "message") {
      setMessage(value)
    }
  }

  const handleSubmit = e => {
    e.preventDefault();

    postMail();
  };

  return (
    <>
      <Section className="pt-0">
        <Container>
          <Row className="justify-content-center">
            <Col lg={6} className="order-lg-1 pt-5 mt-4">
              {postingSuccess ? (
                <Title variant="card" fontSize="24px" className="text-center">Message sent. Thank you!</Title>
              ) : (
                <div>
                  <Title
                    variant="card"
                    fontSize="24px"
                    className="mb-5 text-center"
                  >
                    Send us a message
                  </Title>
                  <FormStyled
                    name="contact1"
                    method="post"
                    onSubmit={handleSubmit}
                  >
                    {/* You still need to add the hidden input with the form name to your JSX form */}
                    <input type="hidden" name="form-name" value="contact1" />

                    <Box mb={3}>
                      <Input
                        type="text"
                        placeholder="Your name"
                        name="name"
                        onChange={handleInputChange}
                        disabled={isPosting ? 'disabled' : undefined}
                        required
                      />
                    </Box>
                    <Box mb={3}>
                      <Input
                        type="email"
                        placeholder="Email address"
                        name="email"
                        onChange={handleInputChange}
                        disabled={isPosting ? 'disabled' : undefined}
                        required
                      />
                    </Box>

                    <Box mb={3}>
                      <Input
                        type="text"
                        placeholder="Subject"
                        name="subject"
                        onChange={handleInputChange}
                        disabled={isPosting ? 'disabled' : undefined}
                        required
                      />
                    </Box>

                    <Box mb={3}>
                      <Input
                        type="text"
                        as="textarea"
                        placeholder="Write your message"
                        rows={4}
                        name="message"
                        onChange={handleInputChange}
                        disabled={isPosting ? 'disabled' : undefined}
                        required
                      />
                    </Box>

                    <Button
                      width="100%"
                      type="submit"
                      borderRadius={10}
                      disabled={isPosting ? 'disabled' : undefined}
                    >
                      Send Message
                      </Button>
                  </FormStyled>
                </div>
              )}
              {postingError ? <Text mt={3}>Something went wrong, please try again later, or email us directly at <Email address="hello@sharptree.io" /></Text> : null}
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
}

export default ContactForm;
